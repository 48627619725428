import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import ThemeConfig from "./utils/ThemeConfig";
import store from "./redux/store";
import './i18n'

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
// import "assets/styles/tailwind.css";
import App from "App";

ReactDOM.render(
  <ThemeConfig>
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="flex h-screen w-screen items-center justify-center">
            <CircularProgress />
          </div>
        }
      >
        <BrowserRouter basename="/">
          <App />
          <Toaster position="bottom-center" reverseOrder={false} />
        </BrowserRouter>
      </Suspense>
    </Provider>
  </ThemeConfig>,
  document.getElementById("root")
);
