import React, { lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { logout, sett } from "./redux/userSlice";
import { ProtectedRoute } from "utils/Protected.route";

// layouts
const Admin = lazy(() => import("./layouts/Admin"));
const Auth = lazy(() => import("./layouts/Auth"));
// views without layouts
const Landing = lazy(() => import("./views/Landing"));
const Index = lazy(() => import("./views/Index"));
const VirtualTourUser = lazy(() => import("./views/virtualtour/VirtualTourUser"));
const ConfigVirtualTour = lazy(() => import("./views/virtualtour/ConfigVirtualTour"));

function App(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // console.log("check expiry");
    if (localStorage.getItem("xtoken")) {
      try {
        const data = jwt_decode(localStorage.getItem("xtoken"));
        if (data) {
          //JWT check if token expired
          if (data.exp * 1000 < Date.now()) {
            toast.error("Sesi telah berakhir.", {
              style: {
                minWidth: "250px",
                border: "1px solid #FF4C4D",
                padding: "16px",
                color: "#000",
                marginBottom: "25px",
              },
              duration: 5000,
              icon: "⚠️",
            });
            dispatch(logout());
          } else {
            // console.log("Noo expiry");
            if (!user.isAuth) {
              dispatch(sett(data));
            }
          }
        }
      } catch (err) {
        console.log(err);
        dispatch(logout());
      }
    }
  });

  return (
    <Switch>
      <Route exact path="/virtualtour/:id" component={VirtualTourUser} />
      <ProtectedRoute exact path="/virtualtouradmin/:id" allowed={["admin"]} component={ConfigVirtualTour} />
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      <Route path="/landing" exact component={Landing} />
      <Route path="/" exact component={Index} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

export default App;
